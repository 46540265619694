import { useMutation } from '@tanstack/vue-query';
import { SavePersonalDetails } from '~/composables/types/PersonalDetails';
import { defineStore } from 'pinia';
import { FilterOptions } from '~/../sa-help/composables/types/Common';
import { AssistanceRequest } from '~/composables/types/AssistanceRequest';
import { Role } from '~/composables/types/Role';
import { generateFilter, globalFetchOptions } from '~/utils/helpers';
import { EnumAssistanceRequestStatus, EnumAssistanceRequestStatusUserFriendly } from '../Enums';
import { ColumnFilter, Pagination, Sort, TsammFilter } from '../types/Common';

export const useAssistanceRequestStore = defineStore('assistanceRequest', () => {
  const filters = ref<{ [key: string]: Array<TsammFilter<string | string[] | boolean | number[]>> }>({
    idPublic: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'ID',
      },
    ],
    pathwayOfHope: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Pathway of Hope',
      },
    ],
    createdBySnapshotId: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Created By',
      },
    ],
    submittedBySnapshotId: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Submitted By',
      },
    ],

    zipCode: [
      {
        value: '',
        operator: 'Contains',
        name: 'Zipcode',
      },
    ],
    applicationIdPublic: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Application ID',
      },
    ],
    status: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Status',
        options: true,
        valueLabel: [],
      },
    ],
    flags: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Flags',
        options: true,
        valueLabel: [],
      },
    ],
    firstName: [
      {
        value: '',
        operator: 'Contains',
        name: 'First Name',
      },
    ],
    lastName: [
      {
        value: '',
        operator: 'Contains',
        name: 'Last Name',
      },
    ],
    serviceAreaId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Service Area',
        options: true,
        valueLabel: [],
      },
    ],
    submittedLanguageId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Language',
        options: true,
        valueLabel: [],
      },
    ],
    assistanceTypeId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Assistance Type',
        options: true,
        valueLabel: [],
      },
    ],
    payee: [
      {
        value: '',
        operator: 'Contains',
        name: 'Payee',
      },
    ],
    amountRequested: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Amount Requested Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Amount Requested Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Amount Requested Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    amountApproved: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Amount Approved Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Amount Approved Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Amount Approved Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    createdAt: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Created On Or After',
        betweenName: 'Created Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Created On Or Before',
      },
    ],
    updatedAt: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Updated On Or After',
        betweenName: 'Updated Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Updated On Or Before',
      },
    ],
    expirationDate: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Expiration Date On Or After',
        betweenName: 'Expiration Date Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Expiration Date On Or Before',
      },
    ],
    nextEligibleDate: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Next Eligible Date On Or After',
        betweenName: 'Next Eligible Date Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Next Eligible Date On Or Before',
      },
    ],
    foodDistributions: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: '# Food Distributions Greater Than Or Equal To',
        convertFromCents: false,
        betweenName: 'Food Distributions Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: '# Food Distributions Less Than Or Equal To',
        convertFromCents: false,
      },
    ],
    assignedTo: [
      {
        value: [],
        operator: 'In',
        name: 'Assigned To',
        options: true,
        valueLabel: [],
      },
    ],
    totalHouseholdMembers: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Household Size Greater Than Or Equal To',
        convertFromCents: false,
        betweenName: 'Household Size Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Household Size Less Than Or Equal To',
        convertFromCents: false,
      },
    ],
    funds: [
      {
        value: [] as string[],
        operator: 'ContainsAny',
        name: 'Funds',
        options: true,
        valueLabel: [],
      },
    ],
    fundingSources: [
      {
        value: [] as string[],
        operator: 'ContainsAny',
        name: 'Funding Sources',
        options: true,
        valueLabel: [],
      },
    ],
  });

  const listStatus = ref<typeof EnumAssistanceRequestStatusUserFriendly>(EnumAssistanceRequestStatusUserFriendly);
  const filterOptions = ref<{
    [key: string]: { label: string | undefined; value: string | undefined | number | null }[] | undefined;
  }>({
    serviceAreaId: [],
    assistanceTypeId: [],
    assignedTo: [],
    status: Object.keys(listStatus.value).map((s) => ({
      label: listStatus.value[s as keyof typeof EnumAssistanceRequestStatusUserFriendly],
      value: s as string,
    })),
    flags: [
      {
        value: EnumFlagType.Chat,
        label: EnumFlagType.Chat,
      },
      {
        value: EnumFlagType.Payee,
        label: EnumFlagType.Payee,
      },
    ],
    funds: [],
    fundingSources: [],
    submittedLanguageId: [],
  });

  const getFromLocalStorage = localStorage.getItem('columnVisibility-applications');
  const columns = ref({
    idPublic: true,
    firstName: true,
    totalHouseholdMembers: true,
    serviceAreaName: true,
    funds: true,
    fundingSources: true,
    assistanceTypeDescription: true,
    amountRequested: true,
    amountApproved: true,
    payee: true,
    status: true,
    flags: true,
    createdAt: true,
    updatedAt: true,
    id: false,
    assignedTo: true,
    applicationIdPublic: true,
    submittedLanguage: false,

    expirationDate: false,
    nextEligibleDate: false,
    foodDistributions: false,
  });

  const setFilters = (f: any) => {
    filters.value.idPublic[0].value = f.query?.idPublic && typeof f.query.idPublic == 'string' ? f.query.idPublic : '';
    filters.value.pathwayOfHope[0].value =
      f.query?.pathwayOfHope && typeof f.query.pathwayOfHope == 'string' ? f.query.pathwayOfHope : '';
    filters.value.createdBySnapshotId[0].value =
      f.query?.createdBySnapshotId && typeof f.query.createdBySnapshotId == 'string' ? f.query.createdBySnapshotId : '';
    filters.value.submittedBySnapshotId[0].value =
      f.query?.submittedBySnapshotId && typeof f.query.submittedBySnapshotId == 'string'
        ? f.query.submittedBySnapshotId
        : '';
    filters.value.assignedTo[0].value =
      f.query?.assignedTo && typeof f.query.assignedTo == 'string' ? f.query.assignedTo.split(',') : [];

    filters.value.assignedTo[0].valueLabel =
      f.query?.assignedToLabels && typeof f.query.assignedToLabels == 'string' ? f.query.assignedToLabels.split(',') : [];

    filters.value.serviceAreaId[0].valueLabel =
      f.query?.serviceAreaIdLabels && typeof f.query.serviceAreaIdLabels == 'string'
        ? f.query.serviceAreaIdLabels.split(',')
        : [];

    filters.value.submittedLanguageId[0].valueLabel =
      f.query?.submittedLanguageIdLabels && typeof f.query.submittedLanguageIdLabels == 'string'
        ? f.query.submittedLanguageIdLabels.split(',')
        : [];

    filters.value.status[0].valueLabel =
      f.query?.statusLabels && typeof f.query.statusLabels == 'string' ? f.query.statusLabels.split(',') : [];

    filters.value.assistanceTypeId[0].valueLabel =
      f.query?.assistanceTypeIdLabels && typeof f.query.assistanceTypeIdLabels == 'string'
        ? f.query.assistanceTypeIdLabels.split(',')
        : [];

    filters.value.funds[0].valueLabel =
      f.query?.fundsLabels && typeof f.query.fundsLabels == 'string' ? f.query.fundsLabels.split(',') : [];

    filters.value.fundingSources[0].valueLabel =
      f.query?.fundingSourcesLabels && typeof f.query.fundingSourcesLabels == 'string'
        ? f.query.fundingSourcesLabels.split(',')
        : [];

    filters.value.applicationIdPublic[0].value =
      f.query?.applicationIdPublic && typeof f.query.applicationIdPublic === 'string' ? f.query.applicationIdPublic : '';
    filters.value.status[0].value = f.query?.status && typeof f.query.status === 'string' ? f.query.status.split(',') : '';
    filters.value.flags[0].value = f.query?.flags && typeof f.query.flags === 'string' ? f.query.flags.split(',') : '';

    filters.value.funds[0].value = f.query?.funds && typeof f.query.funds === 'string' ? f.query.funds.split(',') : '';

    filters.value.fundingSources[0].value =
      f.query?.fundingSources && typeof f.query.fundingSources === 'string' ? f.query.fundingSources.split(',') : '';

    filters.value.firstName[0].value = f.query?.firstName && typeof f.query.firstName === 'string' ? f.query.firstName : '';
    filters.value.lastName[0].value = f.query?.lastName && typeof f.query.lastName === 'string' ? f.query.lastName : '';
    filters.value.serviceAreaId[0].value =
      f.query?.serviceAreaId && typeof f.query.serviceAreaId === 'string' ? f.query.serviceAreaId.split(',') : '';
    filters.value.submittedLanguageId[0].value =
      f.query?.submittedLanguageId && typeof f.query.submittedLanguageId === 'string'
        ? f.query.submittedLanguageId.split(',')
        : '';
    filters.value.assistanceTypeId[0].value =
      f.query?.assistanceTypeId && typeof f.query.assistanceTypeId === 'string' ? f.query.assistanceTypeId.split(',') : '';
    filters.value.payee[0].value = f.query?.payee && typeof f.query.payee === 'string' ? f.query.payee : '';

    const hhms = stringToNumber(f.query.totalHouseholdMembersStart);
    filters.value.totalHouseholdMembers[0].value =
      f.query?.totalHouseholdMembersStart && typeof f.query.totalHouseholdMembersStart === 'string'
        ? hhms > 0
          ? hhms + ''
          : ''
        : '';
    const hhme = stringToNumber(f.query.totalHouseholdMembersEnd);
    filters.value.totalHouseholdMembers[1].value =
      f.query?.totalHouseholdMembersEnd && typeof f.query.totalHouseholdMembersEnd === 'string'
        ? hhme > 0
          ? hhme + ''
          : ''
        : '';

    const ars = f.query.amountRequestedStart;
    filters.value.amountRequested[0].value =
      f.query?.amountRequestedStart && typeof f.query.amountRequestedStart === 'string' ? (ars > 0 ? ars + '' : '') : '';
    const are = f.query.amountRequestedEnd;
    filters.value.amountRequested[1].value =
      f.query?.amountRequestedEnd && typeof f.query.amountRequestedEnd === 'string' ? (are > 0 ? are + '' : '') : '';

    const aas = f.query.amountApprovedStart;
    filters.value.amountApproved[0].value =
      f.query?.amountApprovedStart && typeof f.query.amountApprovedStart === 'string' ? (aas > 0 ? aas + '' : '') : '';
    const aae = f.query.amountApprovedEnd;
    filters.value.amountApproved[1].value =
      f.query?.amountApprovedEnd && typeof f.query.amountApprovedEnd === 'string' ? (aae > 0 ? aae + '' : '') : '';

    filters.value.createdAt[0].value =
      f.query?.createdAtStart && typeof f.query.createdAtStart === 'string' ? f.query.createdAtStart : '';
    filters.value.createdAt[1].value =
      f.query?.createdAtEnd && typeof f.query.createdAtEnd === 'string' ? f.query.createdAtEnd : '';
    filters.value.updatedAt[0].value =
      f.query?.updatedAtStart && typeof f.query.updatedAtStart === 'string' ? f.query.updatedAtStart : '';
    filters.value.updatedAt[1].value =
      f.query?.updatedAtEnd && typeof f.query.updatedAtEnd === 'string' ? f.query.updatedAtEnd : '';

    filters.value.zipCode[0].value = f.query?.zipCode ?? '';

    filters.value.expirationDate[0].value =
      f.query?.expirationDateStart && typeof f.query.expirationDateStart === 'string' ? f.query.expirationDateStart : '';
    filters.value.expirationDate[1].value =
      f.query?.expirationDateEnd && typeof f.query.expirationDateEnd === 'string' ? f.query.expirationDateEnd : '';

    filters.value.nextEligibleDate[0].value =
      f.query?.nextEligibleDateStart && typeof f.query.nextEligibleDateStart === 'string'
        ? f.query.nextEligibleDateStart
        : '';
    filters.value.nextEligibleDate[1].value =
      f.query?.nextEligibleDateEnd && typeof f.query.nextEligibleDateEnd === 'string' ? f.query.nextEligibleDateEnd : '';

    const fds = stringToNumber(f.query.foodDistributionsStart);
    filters.value.foodDistributions[0].value =
      f.query?.foodDistributionsStart && typeof f.query.foodDistributionsStart === 'string' ? (fds > 0 ? fds + '' : '') : '';
    const fde = stringToNumber(f.query.foodDistributionsEnd);
    filters.value.foodDistributions[1].value =
      f.query?.foodDistributionsEnd && typeof f.query.foodDistributionsEnd === 'string' ? (fde > 0 ? fde + '' : '') : '';
  };

  const setFiltersStatus = (status: string[], serviceAreaIds: string[]) => {
    filters.value.status[0].value = status;
    filters.value.serviceAreaId[0].value = serviceAreaIds;
  };

  const setFiltersAssignedToMe = (assignedTo: number[], serviceAreaIds: string[]) => {
    filters.value.assignedTo[0].value = assignedTo;
    filters.value.serviceAreaId[0].value = serviceAreaIds;
  };

  const clearFilters = () => {
    for (const key in filters.value) {
      filters.value[key as keyof typeof filters.value].forEach((f: TsammFilter<string | string[] | number[] | boolean>) => {
        if (f.options) f.value = [];
        else f.value = '';
      });
    }
  };

  const getAll = (filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    //console.log(filter);
    return $fetch<{ success: boolean; data: AssistanceRequest[]; count: number }>(
      `/assistance-requests?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${pagination.perPage}&sort=${
        sort.id
      }&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getAllAdvance = (filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    //console.log(filter);
    return $fetch<{ success: boolean; data: AssistanceRequest[]; count: number }>(
      `/assistance-requests/advance?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${
        pagination.perPage
      }&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getAdvanced = (filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    //console.log(filter);
    return $fetch<{ success: boolean; data: AssistanceRequest[]; count: number }>(
      `/assistance-requests/advance?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${
        pagination.perPage
      }&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getBulkSmsDetails = (filter: any) => {
    return $fetch<{ success: boolean; data: { totalCount: number; sendCount: number } }>(
      `/assistance-requests/bulk-notifications/sms?${generateFilter(filter)}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getBulkSmsAllotment = () => {
    return $fetch<{ success: boolean; data: { id: number; allotment: number; remaining: number; rate: number } }>(
      `/assistance-requests/bulk-notifications/sms/allotment`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const addBulkSms = (filter: any) => {
    return useMutation((formData: { message: string; cost: number; phoneNumbers: string[] }) =>
      $fetch<{ success: boolean; data: string }>(`/assistance-requests/bulk-notifications/sms?${generateFilter(filter)}`, {
        ...globalFetchOptions(),
        method: 'post',
        body: formData,
      })
    );
  };

  const getBulkEmailDetails = (filter: any) => {
    return $fetch<{ success: boolean; data: { totalCount: number; sendCount: number } }>(
      `/assistance-requests/bulk-notifications/email?${generateFilter(filter)}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getBulkEmailPreview = (formData: { body: string; subject: string; emails: string[] }) => {
    return $fetch<{ success: boolean; data: string }>(`/assistance-requests/bulk-notifications/email/preview`, {
      ...globalFetchOptions(),
      method: 'post',
      body: formData,
    });
  };

  const addBulkEmail = (filter: any) => {
    return useMutation((formData: { body: string; subject: string; emails: string[] }) =>
      $fetch<{ success: boolean; data: string }>(`/assistance-requests/bulk-notifications/email?${generateFilter(filter)}`, {
        ...globalFetchOptions(),
        method: 'post',
        body: formData,
      })
    );
  };

  const getCountWithStatusByZipcode = () => {
    return useMutation((formData: { zipcode: string; serviceAreaId: string }) =>
      $fetch<{ success: boolean; data: { status: EnumAssistanceRequestStatus; count: number }[] }>(
        `/assistance-requests/get-count-with-status/${formData.zipcode}?serviceAreaId=${formData.serviceAreaId}`,
        {
          ...globalFetchOptions(),
          method: 'get',
        }
      )
    );
  };

  const getArById = (id: string) => {
    return $fetch<{ success: boolean; data: AssistanceRequest }>(`/assistance-requests/${id}`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const getUserByStatus = (id: string, s: string) => {
    return $fetch<{ success: boolean; data: AssistanceRequest }>(`/assistance-requests/${id}/user-by-status/${s}`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const editAr = (id: string) => {
    return useMutation((formData: { stepId: string; data: any }) =>
      $fetch<SavePersonalDetails>(`/assistance-requests/${id}`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
        retry: false,
      })
    );
  };

  const updateArStatus = (id: string) => {
    return useMutation(
      (formData: {
        status: string;
        reason?: string;
        paymentMethod?: string;
        instructions?: string;
        proofGuid?: any;
        associatedLocationId?: string;
        serviceDate?: string;
        householdIds?: any[];
        clientIDs?: any[];
      }) => {
        const opts: any = {
          ...globalFetchOptions(),
          method: 'PUT',
          retry: false,
        };
        if (formData.reason) opts.body = { reason: formData.reason };
        const copyFormData = { ...formData };
        // Check if householdIds is an object
        if (typeof copyFormData.householdIds === 'object' && !Array.isArray(copyFormData.householdIds)) {
          // Convert object to array
          copyFormData.householdIds = [copyFormData.householdIds];
        }
        if (formData.householdIds) opts.body = copyFormData;
        if (formData.paymentMethod)
          opts.body = {
            paymentMethod: formData.paymentMethod,
            paymentInstructions: formData.instructions ?? '',
            associatedLocationId: formData.associatedLocationId ?? '',
          };
        if (formData.proofGuid) opts.body = { proofGuid: formData.proofGuid };

        return $fetch<SavePersonalDetails>(`/assistance-requests/${id}/status/${formData.status}`, opts);
      }
    );
  };

  const updatePaymentDocument = (id: string) => {
    return useMutation((formData: { proofGuid: any }) => {
      console.log('formdata in updatepmt: ', formData);

      const opts: any = {
        ...globalFetchOptions(),
        method: 'PUT',
        retry: false,
      };
      opts.body = formData.proofGuid;
      return $fetch<SavePersonalDetails>(`/assistance-requests/${id}/proof-of-payment`, opts);
    });
  };

  const getArValidNextStatuses = (currentStatus: string | undefined): string[] => {
    switch (currentStatus) {
      case EnumAssistanceRequestStatus.Accepted:
        return [EnumAssistanceRequestStatus.ApprovalPending, EnumAssistanceRequestStatus.Declined];

      case EnumAssistanceRequestStatus.ApprovalPending:
        return [EnumAssistanceRequestStatus.Approved, EnumAssistanceRequestStatus.Rescinded];

      case EnumAssistanceRequestStatus.Approved:
        return [EnumAssistanceRequestStatus.ApprovedReceivedByFinance];

      case EnumAssistanceRequestStatus.ApprovedReceivedByFinance:
        return [EnumAssistanceRequestStatus.ApprovedFundingSent];

      case EnumAssistanceRequestStatus.ApprovedFundingSent:
        return [EnumAssistanceRequestStatus.ApprovedClosed];

      default:
        return [];
    }
  };

  const addFunds = (id: string, formData: { fundId: string; amount: number }) => {
    return $fetch(`/assistance-requests/${id}/funds/${formData.fundId}?amount=${formData.amount}`, {
      ...globalFetchOptions(),
      method: 'PUT',
      retry: false,
      onResponseError(context: any): Promise<void> | void {
        if (context.response._data.error.Message) {
          toastError('Funds', context.response._data.error.Message);
        }
      },
    });
  };

  const updateFunds = (id: string) => {
    return useMutation((postData: any) =>
      $fetch(`/assistance-requests/${id}/funds`, {
        ...globalFetchOptions(),
        method: 'POST',
        retry: false,
        body: postData,
      })
    );
  };

  const deleteFunds = (id: string) => {
    return useMutation((formData: { fundId: string }) =>
      $fetch(`/assistance-requests/${id}/funds/${formData.fundId}`, {
        ...globalFetchOptions(),
        method: 'DELETE',
        retry: false,
      })
    );
  };

  const exportCsv = async (filter: FilterOptions, sort: Sort, column: ColumnFilter) => {
    return $fetch<any>(
      `/assistance-requests?page=1&pageSize=10000&${generateFilter(filter)}&sort=${sort.id}&order=${
        sort.desc ? 'desc' : 'asc'
      }`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };
  const exportCsvAdvance = async (filter: FilterOptions, sort: Sort, column: ColumnFilter) => {
    return $fetch<any>(
      `/assistance-requests/advance?page=1&pageSize=10000&${generateFilter(filter)}&sort=${sort.id}&order=${
        sort.desc ? 'desc' : 'asc'
      }`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const openFilterModel = ref(false);
  const setOpenFilterModel = (value: boolean) => {
    openFilterModel.value = value;
  };

  const summaryTable = ref<any[]>([]);
  const setSummaryTable = (value: any) => {
    const index = summaryTable.value.findIndex((item) => item.id === value.id);
    console.log(index);
    if (index !== -1) {
      summaryTable.value[index] = value;
    } else {
      summaryTable.value.push(value);
    }
  };
  const clearSummaryTable = () => {
    summaryTable.value = [];
  };

  const savedAssistanceRequests = ref<AssistanceRequest[]>([]);
  const setSavedAssistanceRequests = (value: AssistanceRequest) => {
    savedAssistanceRequests.value.push(value);
  };
  const clearSavedAssistanceRequests = () => {
    savedAssistanceRequests.value = [];
  };
  const findSavedAssistanceRequest = (id: string) => {
    return savedAssistanceRequests.value.find((item) => item.id === id);
  };

  const getRoles = () => {
    return $fetch<{ success: boolean; data: Role[] }>(`/service-areas/roles`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const setAssignedTo = (id: string) => {
    return useMutation((formData: { assignedTo: number | null }) =>
      $fetch<SavePersonalDetails>(`/assistance-requests/${id}/assign`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
        retry: false,
      })
    );
  };

  const setExpirationDate = (id: string) => {
    return useMutation((formData: { expirationDate: string | null }) =>
      $fetch<SavePersonalDetails>(`/assistance-requests/${id}/expiration-date`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
        retry: false,
      })
    );
  };

  const getArValidChangeStatuses = (currentStatus: string | undefined): string[] => {
    switch (currentStatus) {
      case EnumAssistanceRequestStatus.Accepted:
        return [EnumAssistanceRequestStatus.Submitted];

      case EnumAssistanceRequestStatus.ApprovalPending:
        return [EnumAssistanceRequestStatus.Submitted, EnumAssistanceRequestStatus.Accepted];

      case EnumAssistanceRequestStatus.Approved:
      case EnumAssistanceRequestStatus.Declined:
        return [
          EnumAssistanceRequestStatus.Submitted,
          EnumAssistanceRequestStatus.Accepted,
          EnumAssistanceRequestStatus.ApprovalPending,
        ];

      case EnumAssistanceRequestStatus.ApprovedReceivedByFinance:
        return [
          EnumAssistanceRequestStatus.Submitted,
          EnumAssistanceRequestStatus.Accepted,
          EnumAssistanceRequestStatus.ApprovalPending,
          EnumAssistanceRequestStatus.Approved,
          EnumAssistanceRequestStatus.Rescinded,
        ];

      case EnumAssistanceRequestStatus.ApprovedFundingSent:
        return [
          EnumAssistanceRequestStatus.Submitted,
          EnumAssistanceRequestStatus.Accepted,
          EnumAssistanceRequestStatus.Declined,
          EnumAssistanceRequestStatus.ApprovalPending,
          EnumAssistanceRequestStatus.Approved,
          EnumAssistanceRequestStatus.ApprovedReceivedByFinance,
        ];

      default:
        return [];
    }
  };

  const getArValidPriorStatuses = (currentStatus: string | undefined): string[] => {
    switch (currentStatus) {
      case EnumAssistanceRequestStatus.Declined:
      case EnumAssistanceRequestStatus.ApprovalPending:
        return [EnumAssistanceRequestStatus.Accepted];

      case EnumAssistanceRequestStatus.Approved:
      case EnumAssistanceRequestStatus.Rescinded:
        return [EnumAssistanceRequestStatus.ApprovalPending];

      case EnumAssistanceRequestStatus.ApprovedReceivedByFinance:
        return [EnumAssistanceRequestStatus.Approved];

      case EnumAssistanceRequestStatus.ApprovedFundingSent:
        return [EnumAssistanceRequestStatus.ApprovedReceivedByFinance];

      default:
        return [];
    }
  };

  const changeStatus = (id: string, formData: { newStatus: string; comment: string }) => {
    return $fetch<{ success: boolean; data: string }>(
      `/assistance-requests/${id}/change-status/${formData.newStatus}?comment=${formData.comment}`,
      {
        ...globalFetchOptions(),
        method: 'PUT',
        retry: false,
        onResponseError(context: any): Promise<void> | void {
          if (context.response._data.error.Message) {
            toastError('Status Change', context.response._data.error.Message);
          }
        },
      }
    );
  };

  const baseUrl = (assistanceRequestId: string) => {
    return `/assistance-requests/${assistanceRequestId}/distributions`;
  };

  const getAllDistribution = (arId: string, filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    return $fetch<{ success: boolean; data: { result: any[] }; count: number }>(
      `${baseUrl(arId)}?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${pagination.perPage}&sort=${
        sort.id
      }&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getAllDistributionAppointment = (arId: string, filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    const ft = arId ? `filters[0].Field=assistanceRequestId&filters[0].Value=${arId}&filters[0].Operation=IsEqualTo&` : '';
    return $fetch<{ success: boolean; data: { result: any[] }; count: number }>(
      `/assistance-types/appointments?${ft}${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${
        pagination.perPage
      }&sort=${sort.id}&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const getDistributionById = (arId: string, id: string) => {
    return $fetch<{ success: boolean; data: { result: any[] }; count: number }>(`${baseUrl(arId)}/${id}`, {
      ...globalFetchOptions(),
      method: 'get',
    });
  };

  const addDistribution = (arId: string) => {
    return useMutation((formData: { message: string }) => {
      return $fetch<SavePersonalDetails>(`${baseUrl(arId)}`, {
        ...globalFetchOptions(),
        method: 'POST',
        body: formData,
        retry: false,
      });
    });
  };

  const editDistribution = (arId: string, id: string) => {
    return useMutation((formData: { message: string }) => {
      return $fetch<SavePersonalDetails>(`${baseUrl(arId)}/${id}`, {
        ...globalFetchOptions(),
        method: 'PUT',
        body: formData,
        retry: false,
      });
    });
  };

  const deleteDistribution = (arId: string) => {
    return useMutation((data: { id: string }) => {
      return $fetch<SavePersonalDetails>(`${baseUrl(arId)}/${data.id}`, {
        ...globalFetchOptions(),
        method: 'DELETE',
        retry: false,
      });
    });
  };

  return {
    getAll,
    filters,
    setFiltersStatus,
    setFilters,
    listStatus,
    clearFilters,
    exportCsv,
    openFilterModel,
    setOpenFilterModel,
    columns,
    addFunds,
    deleteFunds,
    getArById,
    editAr,
    updateArStatus,
    getArValidNextStatuses,
    summaryTable,
    setSummaryTable,
    clearSummaryTable,
    savedAssistanceRequests,
    setSavedAssistanceRequests,
    clearSavedAssistanceRequests,
    findSavedAssistanceRequest,
    getRoles,
    setAssignedTo,
    setFiltersAssignedToMe,
    updateFunds,
    getArValidChangeStatuses,
    changeStatus,
    updatePaymentDocument,
    getArValidPriorStatuses,
    getCountWithStatusByZipcode,
    getBulkSmsDetails,
    addBulkSms,
    getBulkEmailDetails,
    addBulkEmail,
    getBulkEmailPreview,
    getBulkSmsAllotment,
    filterOptions,
    setExpirationDate,
    getAllDistribution,
    addDistribution,
    editDistribution,
    deleteDistribution,
    getDistributionById,
    getUserByStatus,
    getAdvanced,
    getAllAdvance,
    exportCsvAdvance,
    getAllDistributionAppointment,
  };
});
